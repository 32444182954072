import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {SaleOrder} from '../buy.model';
import {I18NextService} from 'angular-i18next';
import {Subscription} from '../../subscription/subscription.model';
import {PhonenumberService} from '../../phonenumber/phonenumber.service';
import {Block, PhonenumberCheckoutSummary, PhonenumberPrice} from '../../phonenumber/phonenumber.model';
import {HttpClient} from '@angular/common/http';
import {ibanMask} from "@myshared/utils";
import {PC_ONE_PHONE_NUMBERS} from "../select-product/product.model";
import {TrunkEnvironment} from "../../auth/account.model";

@Component({
  selector: 'app-checkout-phonenumber-summary',
  templateUrl: './checkout-phonenumber-summary.component.html',
  styleUrls: []
})
export class CheckoutPhonenumberSummaryComponent extends AutorunComponent implements OnInit {

  @Output() public readonly buy = new EventEmitter<string>();
  @Output() public readonly cancelBuy = new EventEmitter<void>();
  @Input() subscription: Subscription;
  @Input() hideBackBtn = false;
  @Input() phoneNumbers: Block[];

  public monthlyPrice = 0.2;
  public oneTimeFeePrice = 2;
  public saleOrder: SaleOrder;
  public customerAddress: boolean;
  public acceptedAGB = false;
  public acceptedTKG = false;
  public isTKGNeeded = false;

  public summary: PhonenumberCheckoutSummary;

  constructor(
    private m: Model,
    private i18next: I18NextService,
    private phonenumberService: PhonenumberService,
    private http: HttpClient
    ) { super(); }

  ngOnInit() {
    this.autorun(() => {
      this.saleOrder = this.m.buy.currentOrder;
      this.customerAddress = this.saleOrder.customer_address.id !== '';
      this.calculateDiscount();
      this.checkTkgText();
    });

    this.http.get<{pc_one_new_number_surplus: PhonenumberPrice[], pc_one_number_surplus: PhonenumberPrice[]}>
    ('/services/products/cloud').subscribe({
      next: (r) => {
        this.monthlyPrice = r.pc_one_number_surplus[0].list_price;
        this.oneTimeFeePrice = r.pc_one_new_number_surplus[0].list_price;
        this.summary = this.calculateDiscount();
      }
    });
  }

  onBuy() {
    if (!this.acceptedAGB || (this.isTKGNeeded && this.trunkEnv.hasTrunkAccess && !this.acceptedTKG)) {
      return;
    }

    this.buy.emit('');
  }

  get paymentMethod(): string {
    if (this.saleOrder.payment_method === 'sepa') {
      return this.i18next.t('checkout_summary_payment_sepa') as string;
    } else {
      return this.i18next.t('checkout_summary_payment_wire') as string;
    }
  }

  public back() {
    this.cancelBuy.emit();
  }

  public get buyBtnTranslation() {
    return this.usersCompanyId && this.usersCompanyId !== this.saleOrder.invoice_address.id
      ? this.i18next.t('checkout_summary_create_customer_offer',
        { email: this.saleOrder.contact.email })
      : this.i18next.t('checkout_summary_buy_now');
  }

  public get usersCompanyId() {
    return this.m.account.currentUser.company_id;
  }

  private calculateDiscount(): PhonenumberCheckoutSummary {
    const currentUsers = this.m.buy.currentOrder.current_users ?? +this.subscription.users ?? 0;
    const phoneNumbers = this.phoneNumbers ?? this.m.phonenumber.blocks;
    const currentNumbers = +this.phonenumberService.getPhonenumberUsedInSubscriptionSize(
      this.m.buy.currentOrder.aid,
      phoneNumbers,
      this.saleOrder.name
    );
    const newNumbers = this.getNewNumberSize();
    const maxFreeNumbers = Math.max(0, ((currentUsers * PC_ONE_PHONE_NUMBERS) - currentNumbers));
    const freeSaleOrderNumbers = Math.min(maxFreeNumbers, newNumbers);
    const discount = (freeSaleOrderNumbers / newNumbers) * 100;
    const calculatedAdditionalNumbers = discount < 100 ? (newNumbers - freeSaleOrderNumbers) : 0;
    const summary = new PhonenumberCheckoutSummary();

    summary.oneTimeFee = {
      included: {
        quantity: freeSaleOrderNumbers,
        price: 0,
        summaryPrice: 0
      },
      additional: {
        quantity: calculatedAdditionalNumbers,
        price: this.oneTimeFeePrice,
        summaryPrice: calculatedAdditionalNumbers * this.oneTimeFeePrice
      }
    };
    summary.monthly = {
      included: {
        quantity: freeSaleOrderNumbers,
        price: 0,
        summaryPrice: 0
      },
      additional: {
        quantity: calculatedAdditionalNumbers,
        price: this.monthlyPrice,
        summaryPrice: calculatedAdditionalNumbers * this.monthlyPrice
      }
    };

    return summary;
  }

  public get maskedIban() {
    if (this.saleOrder.payment_method === 'sepa' && this.saleOrder?.payment_details?.acc_number) {
      return ibanMask(this.saleOrder.payment_details.acc_number);
    }

    return '';
  }

  public get countryTranslationCode() {
    return this.trunkEnv.translationCode;
  }

  public acceptAGB(accepted: boolean) {
    this.acceptedAGB = accepted;
  }
  public acceptTKG(accepted: boolean) {
    this.acceptedTKG = accepted;
  }

  public get tenantCountryCode() {
    return this.trunkEnv.tenantCountryCode
  }

  /**
   * We need to check where we get the trunk env.
   * Most of the time we will get this from the subscription, but on the customer
   * approval step we have order with this information in it.
   */
  public get trunkEnv(): TrunkEnvironment {
    if (this.saleOrder) {
      return this.saleOrder.invoice_address.trunkEnvironment;
    }

    if (this.subscription) {
      return this.subscription.trunkEnvironment;
    }

    return new TrunkEnvironment();
  }

  public get tkgText() {
    const tenantCountryCode = (this.tenantCountryCode ?? '').toLowerCase();
    return `checkout_one_summary_accept_tkg_${tenantCountryCode}`;
  }

  private getNewNumberSize() {
    let size = 0;
    this.saleOrder.lines.map(r => {
      if (r.product.toUpperCase() === 'PC-ONE-NEW-NUMBER' || r.product.toUpperCase() === 'PC-ONE-PORT-NUMBER') {
        size += r.product_uom_qty;
      }
    });
    return size;
  }

  private checkTkgText() {
    if (!this.subscription && !this.saleOrder) return;
    this.isTKGNeeded = this.i18next.exists(this.tkgText, {})
  }
}
