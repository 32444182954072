import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ProductPriceDiscount, SlaProduct} from "../../buy/select-product/product.model";
import {Subscription} from "../../subscription/subscription.model";
import {SubscriptionService} from "../../subscription/subscription.service";
import {WorkflowService} from "../workflow.service";
import {WorkflowComponent} from "../workflow.component";
import {Model} from "../../app.model";

@Component({
  selector: 'app-workflow-change-sla',
  templateUrl: './workflow-change-sla.component.html',
})
export class WorkflowChangeSlaComponent extends WorkflowComponent implements OnInit {
  public subscription: Subscription;
  public slaProducts: SlaProduct[];
  public selectedSLA = '';

  public initialized = false;

  constructor(protected route: ActivatedRoute,
              private router: Router,
              private workflowService: WorkflowService,
              private http: HttpClient,
              private subscriptionService: SubscriptionService,
              private m: Model) {
    super(route);
  }

  async ngOnInit() {
    super.ngOnInit();
    const subscription = await firstValueFrom(this.subscriptionService.getSubscriptionDetails(+this.applianceId));
    this.subscription = Subscription.fromJson(subscription);

    if (this.subscription.isCancelled) {
      this.resetWorkflow();
      return;
    }

    if (!!this.subscription.scheduledSLAOrder) {
      this.resetWorkflow();
      return;
    }

    const cloudProducts = await firstValueFrom(
        this.http.get<{ sla }>('/services/products/cloud')
    );
    this.slaProducts = cloudProducts.sla.map(r => SlaProduct.fromJson(r));

    this.initialized = true;
  }

  public onProductSelected(sla: string) {
    if (sla === this.subscription.sla_product) {
      console.error('Same SLA product choose');
      return;
    }

    const slaProduct = this.slaProducts.find(r => r.name === sla);

    this.selectedSLA = slaProduct.name.replace('PC-ONE-', '');
    if (slaProduct?.type === 'upgrade' || slaProduct?.type === 'downgrade') {
      this.navigateToNextWorkflow(slaProduct.type);
    }
  }

  public onBack() {
    this.resetWorkflow();
  }

  private navigateToNextWorkflow(type: 'upgrade' | 'downgrade') {
    let nav = ['/do', `${type}-sla`, this.subscription.appliance_id];
    if (!this.subscription.is_my && this.m.account.currentUser.companyIsPartner) {
      nav = ['/do', 'partner', `${type}-sla`, this.subscription.appliance_id];
    }
    if (this.m.account.currentUser.companyIsPC20Csp) {
      nav = ['/do', 'csp', `${type}-sla`, this.subscription.appliance_id];
    }

    this.router.navigate(nav, {
      queryParams: { sla: this.selectedSLA }
    });
  }
}
