import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { AutorunComponent } from '@myshared/autorun.component';
import { I18NextService } from 'angular-i18next';
import {TariffService} from '../../tariff.service';
import {autorun} from 'mobx';
import {Tariff, tariffFlatNames} from '../../tariff.model';
import {Model} from '../../../app.model';
import {Subscription} from "../../../subscription/subscription.model";

@Component({
    selector: 'app-tariff-select',
    templateUrl: './tariff-select.component.html'
})
export class TariffSelectComponent extends AutorunComponent implements OnInit {

    public flatrates = [];

    @Input() currentTariff: string;
    @Input() subscription: Subscription;

    @Output()
    public back = new EventEmitter<number>();
    @Output()
    public selected = new EventEmitter<string>();

    constructor(
        private i18next: I18NextService,
        private tariffService: TariffService,
        private m: Model
    ) {
        super();
    }

    ngOnInit() {
        autorun(() => {
            if (this.m.tariff.flatrates.length > 0) {
                this.flatrates = this.getTariffFlatrates(this.m.tariff.flatrates);
            }
        });
        this.tariffService.refresh();
    }

    getTariffFlatrates(flatrates: Tariff[]) {
        const tariffFlat = [{}];
        for (const flat of flatrates) {
            if (tariffFlatNames.indexOf(flat.name.toUpperCase()) !== -1) {
                tariffFlat[flat.name] = flat;
            }
        }
        return tariffFlat;
    }

    public onBack() {
        this.back.emit();
    }

    public onTariffSelected(tariff: string) {
        this.selected.emit(tariff);
    }

    public get isAbovePopularSelected() {
      return this.currentTariff === 'PC-ONE-TARIFF-FLAT-L' || this.currentTariff === 'PC-ONE-TARIFF-FLAT-XL';
    }

    public get countryTranslationCode() {
        const tenantCountryCode = this.subscription.trunkEnvironment.tenantCountryCode;
        return `-${tenantCountryCode.toLowerCase()}`;
    }
}
