<div class="price-card card card-nb h-100"
     [ngClass]="cssClasses">
  <div class="d-flex flex-column">
    <div class="price-card-header phonenumber">
      <div class="d-flex align-items-center flex-column phonenumber-type-container">
        <div class="subscription-type h3">{{ typeText | i18next }}</div>
      </div>
    </div>
    <div class="price-card-body mt-4">
      <div class="container">
        <div class="d-flex justify-content-center text-justify">
          <div class="d-flex flex-row">
            <div class="d-flex flex-grow-1 flex-column">
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-5 mb-5">
        <div class="d-flex justify-content-center text-justify">
          <div class="d-flex w-75">
            <div><i class="far fa-question-circle red"></i></div>
            <div class="ml-2">{{ typeText + "_hint" | i18next }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="price-card-footer">
      <ng-content></ng-content>
    </div>
  </div>
</div>
