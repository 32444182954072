import {Component, OnDestroy, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {firstValueFrom} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {ProductPriceDiscount} from "../../buy/select-product/product.model";
import {Subscription} from "../../subscription/subscription.model";
import {SubscriptionService} from "../../subscription/subscription.service";
import {WorkflowService} from "../workflow.service";
import {WorkflowComponent} from "../workflow.component";
import {Model} from "../../app.model";
import {ToastService} from "../../toast.service";

@Component({
  selector: 'app-workflow-change-cu',
  templateUrl: './workflow-change-cu.component.html',
})
export class WorkflowChangeCuComponent extends WorkflowComponent implements OnInit {
  public pcOnePrice: number;
  public pcOneDiscount: ProductPriceDiscount[];
  public subscription: Subscription;
  public cuSelected = false;
  public selectedCU: number;

  public initialized = false;

  constructor(protected route: ActivatedRoute,
              private router: Router,
              private workflowService: WorkflowService,
              private http: HttpClient,
              private subscriptionService: SubscriptionService,
              private toastService: ToastService,
              private m: Model) {
    super(route);
  }

  async ngOnInit() {
    super.ngOnInit();
    const subscription = await firstValueFrom(this.subscriptionService.getSubscriptionDetails(+this.applianceId));
    this.subscription = Subscription.fromJson(subscription);

    if (this.subscription.isCancelled) {
      this.resetWorkflow();
      return;
    }

    if (!!this.subscription.scheduledCUOrder) {
      this.resetWorkflow();
      return;
    }

    const cloudProducts = await firstValueFrom(
        this.http.get<{ pc_one_price, pc_one_discount }>('/services/products/cloud')
    );

    this.pcOnePrice = cloudProducts.pc_one_price || 0;
    this.pcOneDiscount = cloudProducts.pc_one_discount.map(r => ProductPriceDiscount.fromJson(r));

    this.initialized = true;
  }

  public onProductSelected(cu: number) {
    const currentCU = +this.subscription.users;
    this.selectedCU = cu;
    if (currentCU < cu) {
      // upgrade
      this.cuSelected = true;
      this.navigateToNextWorkflow('upgrade');
    } else if (currentCU > cu) {
      this.cuSelected = true;
      this.navigateToNextWorkflow('downgrade');
    } else {
      this.toastService.error('upgrade_one_cu_error_same');
    }
  }

  public onBackChangeCu() {
    this.resetWorkflow();
  }

  private navigateToNextWorkflow(type: 'upgrade' | 'downgrade') {
    let nav = ['/do', `${type}-cu`, this.subscription.appliance_id];
    if (!this.subscription.is_my && this.m.account.currentUser.companyIsPartner) {
      nav = ['/do', 'partner', `${type}-cu`, this.subscription.appliance_id];
    }
    if (this.m.account.currentUser.companyIsPC20Csp) {
      nav = ['/do', 'csp', `${type}-cu`, this.subscription.appliance_id];
    }
    this.router.navigate(nav, {
      queryParams: { cu: this.selectedCU }
    });
  }
}
