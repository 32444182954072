import {Injectable} from '@angular/core';
import { I18NextService } from 'angular-i18next';
import {FormGroup} from '@angular/forms';
import {
    DynamicFormGroupModel,
    DynamicFormService,
    DynamicCheckboxModel,
    DynamicInputModel,
    DynamicFormLayout,
    DynamicFormControlModel,
    DynamicSelectModel,
    DynamicRadioGroupModel,
    DynamicFormComponentService
} from '@ng-dynamic-forms/core';
import {Observable} from 'rxjs';


@Injectable()
export class PCFormsService {

    private builderId: string;

    constructor(private i18next: I18NextService,
                private formService: DynamicFormService,
                private dynamicFormComponentService: DynamicFormComponentService) {

    }

    public newBuilder(id: string): PCFormBuilder {
        this.builderId = id;
        return new PCFormBuilder(id, this.i18next, this.formService, this.dynamicFormComponentService);
    }
}

export interface PCCssDefinition {
    container?: string;
    control?: string;
    errors?: string;
    group?: string;
    hint?: string;
    host?: string;
    label?: string;
    option?: string;
}

export interface PCFieldDefinition {
    id: string;
    placeholder?: string;
    validators?: Object;
    errorMessages?: Object;
    options?: Object;
    elementClass?: PCCssDefinition;
    gridClass?: PCCssDefinition;
    tabIndex?: number;
    autoFocus?: boolean;
    readOnly?: boolean;
    label?: string;
}

export interface PCContainerDefinition {
    id: string;
    legend?: string;
    options?: string;
    elementClass?: PCCssDefinition;
    gridClass?: PCCssDefinition;
}

export interface PCSelectValue<T> {
    label: string;
    value: T;
}

export class PCFormContainer {

    protected _builder: PCFormBuilder;
    protected _group: DynamicFormGroupModel;
    protected _layout: DynamicFormLayout = {};

    protected i18next: I18NextService;

    protected populateOptions(definition: PCFieldDefinition): any {
        const placeHolderKey = this.fullkey(definition.id, 'placeholder');
        const placeHolderText = definition.placeholder ? definition.placeholder : placeHolderKey;
        const o = {
            id: definition.id,
            label : definition.label || definition.label === '' ? definition.label : this.t(definition.id, 'label'),
            placeholder: definition.placeholder
              || this.i18next.exists(placeHolderKey, {})
              ? this.i18next.t(placeHolderText) as string : undefined,
            validators: definition.validators || {},
            errorMessages: definition.errorMessages || {},
            tabIndex: definition.tabIndex,
            autoFocus: definition.autoFocus,
            readOnly: definition.readOnly || false,
            disabled: definition.readOnly || false
        };
        // add user-speciefied options
        Object.assign(o, definition.options);
        // auto-add translated error messages
        Object.keys(o.validators).forEach(vname => {
            if (!o.errorMessages[vname]) {
                o.errorMessages[vname] =  this.t(definition.id, 'error_' + vname, {field: o.label});
            }
        });
        return o;
    }

    protected populateLayout(definition: PCFieldDefinition) {
        this._layout[definition.id] = {
            grid: definition.gridClass as {} || {},
            element: definition.elementClass as {} || {}
        };
    }

    protected populateContainerLayout(definition: PCContainerDefinition) {
        definition.gridClass = definition.gridClass || {};
        definition.elementClass = definition.elementClass || {};
    }

    addTextInput(definition: PCFieldDefinition): DynamicInputModel {
        const o = this.populateOptions(definition);
        const model = new DynamicInputModel(o);
        this._group.add(model);
        this.populateLayout(definition);
        return model;
    }

    addRadioGroup(definition: PCFieldDefinition, options: string[], selectedValue: string): DynamicRadioGroupModel<string> {
        const o = this.populateOptions(definition);
        o.options = options.map(itm => ({ label: this.i18next.t(this.fullkey(definition.id, itm)), value: itm }));
        const model = new DynamicRadioGroupModel<string>(o);
        this._group.add(model);
        this.populateLayout(definition);
        return model;
    }

    protected fullkey(id: string, key: string): string {
        return this._builder.id + '_' + id + '_' + key;
    }

    t(id: string, key: string, options?: any): string {
        return this.i18next.t(this.fullkey(id, key), options) as string;
    }

    addPassword(definition: PCFieldDefinition): DynamicInputModel {
        definition.options = definition.options || {};
        definition.options['inputType'] = 'password';
        return this.addTextInput(definition);
    }

    addCheckbox(definition: PCFieldDefinition): DynamicCheckboxModel {
        const o = this.populateOptions(definition);
        const model = new DynamicCheckboxModel(o);
        this._group.add(model);
        this.populateLayout(definition);
        return model;
    }
    addSelect<T>(definition: PCFieldDefinition,
                 values: Observable<PCSelectValue<T>[]>|PCSelectValue<T>[]|'CountrySelectionDisabled',
                 selectedValue?: T): DynamicSelectModel<T> | DynamicInputModel {
        // Its not possible to deactivate a select input with readonly on dynamic forms
        // For this case, we will use a addTextInput. See: https://pascom.atlassian.net/browse/IT-1815
        if (definition?.readOnly || values === 'CountrySelectionDisabled') {
          definition.readOnly = true; // in this case always true
          return this.addTextInput(definition);
        }
        const o = this.populateOptions(definition);
        o.options = values;
        o.value = selectedValue;
        const model = new DynamicSelectModel<T>(o);
        this._group.add(model);
        this.populateLayout(definition);
        return model;
    }

    addContainer<T extends PCFormContainer>(TYPE: { new(): T ; }, definition: PCContainerDefinition ): PCFormContainer {
        const child = new TYPE();
        child.i18next = this.i18next;
        child._layout = this._layout;
        child._group = new DynamicFormGroupModel({
            id : definition.id,
            legend: definition.legend || null
        });
        this._group.add(child._group);
        child._builder = this._builder;
        child.populateContainerLayout(definition);
        child._layout[definition.id] = {
            grid: definition.gridClass as {} || {},
            element: definition.elementClass as {} || {}
        };
        return child;
    }
}

export class PCFormRow extends PCFormContainer {

    protected populateContainerLayout(definition: PCContainerDefinition) {
        definition.gridClass = definition.gridClass || { container: 'form-group' };
        definition.elementClass = definition.elementClass || { control: 'form-row' };
    }

    protected populateLayout(definition: PCFieldDefinition) {
        if (!definition.gridClass) {
            definition.gridClass =  {
                host: 'col',
            };
        }

        super.populateLayout(definition);
    }
}

export class PCFormBuilder extends PCFormContainer {

    constructor(id: string,
                protected i18next: I18NextService,
                protected formService: DynamicFormService,
                private dynamicFormComponentService: DynamicFormComponentService) {
        super();
        this._builder = this;
        this._group = new DynamicFormGroupModel({
            id : id,
        });
    }

    get id(): string {
        return this._group.id;
    }

    get model(): DynamicFormControlModel[] {
        return [this._group];
    }

    get layout(): DynamicFormLayout {
        return this._layout;
    }

    createFormGroup(): FormGroup {
        return this.formService.createFormGroup(this.model);
    }

    /**
     * This autofocus method can only used in ngAfterViewInit (AfterViewInit Interface)
     * On multiple steps (more then one builder) please define the formControlId for every step
     *
     * @param {string} formControlId (default: empty to use the form builder id)
     * @param {string} formControlClass (default: empty to use the form-control)
     */
    autoFocus(formControlClass: string = 'form-control') {
        if (!this.id) {
            return;
        }

        // We need to set a time out, because it will be happen that the view isn't initialized
        // when we using multiple forms on one component
        setTimeout(() => {
            const controlRef = this.dynamicFormComponentService.getFormControlRef(this.id);

            if (controlRef && controlRef.location) {
                const htmlEl = controlRef.location.nativeElement;
                const matches = htmlEl.getElementsByClassName(formControlClass);

                for (const m of matches) {
                    if (m.autofocus) {
                        m.focus();
                        return;
                    }
                }
            }
        });
    }
}
