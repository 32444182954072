import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Model} from '../../app.model';
import {AutorunComponent} from '@myshared/autorun.component';
import {SaleOrder, SaleOrderLine} from '../buy.model';
import {I18NextService} from 'angular-i18next';
import {normalizedShortLang} from '@myshared/i18nutils';
import {ibanMask} from "@myshared/utils";
import {CompanyService} from "../../company/company.service";
import {Subscription} from "../../subscription/subscription.model";
import {TrunkEnvironment} from "../../auth/account.model";

@Component({
  selector: 'app-checkout-one-summary',
  templateUrl: './checkout-one-summary.component.html',
  styleUrls: []
})
export class CheckoutOneSummaryComponent extends AutorunComponent implements OnInit {

  @Output() public readonly buy = new EventEmitter<string>();
  @Output() public readonly back = new EventEmitter();

  @Input() public historyBack = true;
  @Input() public backLabel = 'app_back';
  @Input() public hideBackBtn = false;
  @Input() public migrationCredit?: number;
  @Input() public subscription: Subscription;

  public saleOrder: SaleOrder;
  public appliance_name: string;
  public language: string;
  public pcCredit: SaleOrderLine;
  public isPC20CSP: boolean;
  public acceptedAGB = false;
  public acceptedTKG = false;
  public isCompanyDataLoaded = false;
  public isTKGNeeded = false;

  readonly PC_CREDIT = 'PC-CREDIT-MONTH';

  constructor(
    private m: Model,
    private i18next: I18NextService,
    private companyService: CompanyService
    ) { super(); }

  ngOnInit() {
    this.autorun(() => {
      this.trunkEnv;
      this.appliance_name = 'pascom.cloud';
      this.saleOrder = this.m.buy.currentOrder;
      this.pcCredit = this.saleOrder.lines.find(r => r.product === this.PC_CREDIT);
      this.language = normalizedShortLang(this.m.account.currentUser.lang).toLowerCase();
      this.isPC20CSP = this.m.account.currentUser.companyIsPC20Csp;
      if (this.isPC20CSP && !this.isCompanyDataLoaded) {
        this.companyService.refresh();
        this.isCompanyDataLoaded = true;
      }
      this.checkTkgText();
    });
  }

  onBuy() {
    if (!this.acceptedAGB || (this.isTKGNeeded && this.trunkEnv.hasTrunkAccess && !this.acceptedTKG)) {
      return;
    }

    this.buy.emit('');
  }

  onBack() {
    this.back.emit();
  }

  get paymentMethod(): string {
    if (this.saleOrder.payment_method === 'sepa') {
      return this.i18next.t('checkout_summary_payment_sepa') as string;
    } else {
      return this.i18next.t('checkout_summary_payment_wire') as string;
    }
  }

  public acceptAGB(accepted: boolean) {
    this.acceptedAGB = accepted;
  }
  public acceptTKG(accepted: boolean) {
    this.acceptedTKG = accepted;
  }

  public get buyBtnTranslation() {
    return this.usersCompanyId && this.usersCompanyId !== this.saleOrder.invoice_address.id && !this.isPC20CSP
      ? this.i18next.t('checkout_summary_create_customer_offer',
        { email: this.saleOrder?.contact?.email })
      : this.i18next.t('checkout_summary_buy_now');
  }

  public get usersCompanyId() {
    return this.m.account.currentUser.company_id;
  }

  public get maskedIban() {
    if (this.saleOrder.payment_method === 'sepa') {
      return ibanMask(this.saleOrder?.payment_details?.acc_number);
    }

    return '';
  }

  public get name() {
    return this.saleOrder.invoice_address.name;
  }

  public get street() {
    return this.saleOrder.invoice_address.street;
  }
  public get street2() {
    return this.saleOrder.invoice_address.street2;
  }
  public get zipAndCity() {
    return `${this.saleOrder.invoice_address.zip} ${this.saleOrder.invoice_address.city}`;
  }
  public get country() {
    return this.saleOrder.invoice_address.country.name;
  }
  public get hasDiscount(): boolean {
    if (this.saleOrder) {
      return this.saleOrder.lines.reduce((acc, l) => l.discount > 0 || acc, false);
    }
    return false;
  }

  public get countryTranslationCode() {
    return this.trunkEnv.translationCode;
  }

  public get tenantCountryCode() {
    return this.trunkEnv.tenantCountryCode;
  }

  /**
   * We need to check where we get the trunk env.
   * Most of the time we will get this from the subscription, but on the customer
   * approval step we have order with this information in it.
   */
  public get trunkEnv(): TrunkEnvironment {
    if (this.saleOrder) {
      return this.saleOrder.invoice_address.trunkEnvironment;
    }

    if (this.subscription) {
      return this.subscription.trunkEnvironment;
    }

    return new TrunkEnvironment();
  }

  public get tkgText() {
    const tenantCountryCode = (this.tenantCountryCode ?? '').toLowerCase();
    return `checkout_one_summary_accept_tkg_${tenantCountryCode}`;
  }

  private checkTkgText() {
    if (!this.subscription && !this.saleOrder) return;
    this.isTKGNeeded = this.i18next.exists(this.tkgText, {})
  }
}
