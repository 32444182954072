<div *ngIf="ticket">
    <div *ngIf="isTicketView">
        <div class="mb-3">
            <button name="back" class="btn btn-outline-primary" [routerLink]="['/support']" >{{ 'app_back' | i18next }}</button>
        </div>
        <div class="d-flex flex-row align-items-center mb-3 ml-3 mr-3">
            <div class="h3">{{ ticket.title }}</div>
            <div class="ml-auto bd-highlight" *ngIf="!isTicketClosed">
            <button name="close-ticket" class="btn btn-outline-primary" (click)="closeTicket()" [disabled]="isRequesting">{{ 'support_ticket_close' | i18next }}</button>
            </div>
        </div>
        <div class="ticket">
            <div class="sub">
                <div class="d-flex">
                    <span>{{ "support_ticket_number" | i18next }} {{ ticket.number }}</span>
                    <span class="ml-3">{{ "support_ticket_created" | i18next }} {{ ticket.createdAt | localized_datetime }}</span>
                    <span class="ml-3" *ngIf="ticket.createdByName !== ' '">{{ "support_ticket_created_by" | i18next }} {{ ticket.createdByName }}</span>
                    <span class="ml-3">{{ "support_ticket_state" | i18next }} {{ ticket.stateTranslationString | i18next }}</span>
                    <span class="ml-3" *ngIf="ticket.applianceId">
                        {{ "support_ticket_subscription" | i18next }} <a [routerLink]="['/subscriptions', ticket.applianceId]"  class="btn-link" name="appliance">{{ ticket.applianceId }}</a>
                    </span>
                </div>
            </div>
            <div class="articles">
                <app-article-card *ngFor="let article of ticket.articles" [article]="article" [ticketId]="ticket.id"></app-article-card>
            </div>
            <div class="editor mt-3 mb-3" *ngIf="!isTicketClosed">
                <app-article-editor [(message)]="articleMessage"></app-article-editor>
                <div class="d-flex bd-highlight mt-3 mb-3">
                    <div class="mr-auto ticket">
                        <app-upload-attachments-form *ngIf="!isRequesting" id="upload-form" [applianceId]="ticket.applianceId" (onFilesChanged)="onFilesChanged($event)" (onUpload)="onUpload($event)"></app-upload-attachments-form>
                    </div>
                    <div>
                    <button name="detail" class="btn btn-outline-primary" (click)="sendArticle()" [disabled]="isRequesting || !articleMessage || isUploading">{{'app_reply' | i18next}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-close-ticket-warning *ngIf="!isTicketView" [ticketNumber]="ticket.number" (confirm)="onCloseConfirm()" (back)="onCloseCancel()"></app-close-ticket-warning>
</div>
