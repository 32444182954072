<div class="app-header shadow-sm">
  <nav class="navbar">
    <div class="d-flex justify-content-start align-items-center align-self-center">
      <div class="navbar-toggle ml-3 d-sm-none" *ngIf="isAuthorized && showMenu">
        <button class="menu-toggle" [ngClass]="{ 'opened': isMobileMenuActive }" (click)="toggleMobileMenu()"
                id="menu-toggle" aria-expanded="false"><span class="screen-reader-text">Menu</span>
          <svg class="icon icon-menu-toggle" aria-hidden="true" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100">
            <g class="svg-menu-toggle">
              <path class="line line-1" d="M5 13h90v14H5z"/>
              <path class="line line-2" d="M5 43h90v14H5z"/>
              <path class="line line-3" d="M5 73h90v14H5z"/>
            </g>
          </svg>
        </button>
      </div>
      <div class="navbar-brand ml-3 ml-md-4" [ngClass]="{ 'cursor-default': inWorkflow }">
      </div>
    </div>
    <div class="form-inline mr-2 ml-auto" *ngIf="isAuthorized">
      <app-select-tenant *ngIf="!inMaintenance"></app-select-tenant>
      <div *ngIf="inMaintenance">
        <i class="far fa-user-circle red icon-size-bigger navbar-profile-icon"></i>&nbsp;
        <span class="navbar-profile-text d-none d-sm-inline">{{name}}</span>
      </div>
      <app-user-menu *ngIf="!inMaintenance" [name]="name"></app-user-menu>
    </div>
  </nav>
</div>

<!--Mobile Navigation Bar-->

<div class="mobile-menu d-sm-none" *ngIf="showMenu && isMobileMenuActive">
  <div class="mobile-menu-wrapper">
    <div class="mobile-menu-content">
      <div class="mt-3" [ngClass]="{ 'mt-5': showStickyMessages }">
        <ul class="nav f-flex flex-column">
          <li class="nav-item">
            <!--<a class="nav-link d-flex flex-row" [routerLink]="isPartner ? '/subscriptions/customer' : '/subscriptions/my'">-->
            <a class="nav-link d-flex flex-row" (click)="openRoute()" routerLinkActive="active" routerLink="/subscriptions">
              <span class="nav-link-icon icon col-1"><i class="fas fa-user-edit icon-size-big"></i></span>
              <span class="nav-link-text nav-text-md col-11">{{ 'navigation_subscriptions' | i18next }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="canEnterPhonenumbers">
            <a class="nav-link d-flex flex-row" (click)="openRoute()" routerLinkActive="active" routerLink="/phonenumbers">
              <span class="nav-link-icon icon col-1"><i class="fas fa-phone-alt icon-size-big"></i></span>
              <span class="nav-link-text nav-text-md col-11">{{ 'navigation_phonenumbers' | i18next }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link d-flex flex-row" (click)="openRoute()" routerLinkActive="active" routerLink="/support">
              <span class="nav-link-icon icon col-1"><i class="fas fa-headset icon-size-big"></i></span>
              <span class="nav-link-text nav-text-md col-11">{{ 'navigation_support' | i18next }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="isMypascomAdmin">
            <a class="nav-link d-flex flex-row" (click)="openRoute()" routerLinkActive="active" [routerLink]="['/orders']">
              <span class="nav-link-icon icon col-1"><i class="fas fa-shopping-cart icon-size-big"></i></span>
              <span class="nav-link-text nav-text-md col-11">{{'navigation_orders' | i18next }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="isPartner">
            <a class="nav-link d-flex flex-row" (click)="openRoute()" routerLinkActive="active" routerLink="/customers">
              <span class="nav-link-icon icon col-1"><i class="fa fas fa-users icon-size-big"></i></span>
              <span class="nav-link-text nav-text-md col-11">{{ 'navigation_customers' | i18next }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="isMypascomAdmin">
            <a class="nav-link d-flex flex-row" (click)="openRoute()" routerLinkActive="active" routerLink="/company">
              <span class="nav-link-icon icon col-1"><i class="far fa-building icon-size-big"></i></span>
              <span class="nav-link-text nav-text-md col-11">{{ 'navigation_company' | i18next }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="isMypascomAdmin && isPartnershipAllowed">
            <a class="nav-link d-flex flex-row" (click)="openRoute()" routerLinkActive="active" [routerLink]="['/partner']">
              <span class="nav-link-icon icon col-1"><i class="far fa-handshake icon-size-big"></i></span>
              <span class="nav-link-text nav-text-md col-11">{{ 'navigation_partner' | i18next }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="hasAgreements">
            <a class="nav-link d-flex flex-row" (click)="openRoute()" routerLinkActive="active" routerLink="/agreement-management/agreements">
              <span class="nav-link-icon icon col-1"><i class="fas fa-file-signature icon-size-big"></i></span>
              <span class="nav-link-text nav-text-md col-11">{{ 'navigation_agreement' | i18next }}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="isPartner && !!partnerMediaLink">
            <a class="nav-link d-flex flex-row" target="partnerdownload" (click)="openRoute()"
               [href]="partnerMediaLink">
              <span class="nav-link-icon icon col-1"><i class="fas fa-cloud-download-alt icon-size-big"></i></span>
              <span class="nav-link-text nav-text-md col-11">{{ 'navigation_partner_downloads' | i18next }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

