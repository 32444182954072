<app-content-header [hideBackButton]="loggedInUser" [headline]="(!loggedInUser ? 'contact_details_header' : 'profile') | i18next"></app-content-header>
<div class="mb-4" *ngIf="!userIsActive">
  <i class="fa fa-info-circle red"></i><span class="ml-2">{{ 'contact_mypascom_not_activated_yet' | i18next }}</span>
</div>
<div class="mb-4" *ngIf="loggedInUser">
  <i class="fa fa-info-circle red"></i><span class="ml-2">{{ 'contact_mypascom_logged_in_user_hint' | i18next }}</span>
</div>
<div class="card card-nb" *ngIf="contact">
  <div class="card-body">
    <h3 class="h3 red mb-3" *ngIf="loggedInUser">{{ 'contact_details_header' | i18next }}</h3>
    <div class="row">
      <div class="col-12" [ngClass]="{ 'col-md-6': isAdmin }">
        <dynamic-primeng-form [group]="formGroup" [layout]="formLayout" [model]="formModel"></dynamic-primeng-form>
      </div>
      <div class="col-12" [ngClass]="{ 'col-md-6': isAdmin }" *ngIf="isAdmin">
        <h5>{{'contact_mypascom_account_description_header' |  i18next}}</h5>
        <div><i class="fa fa-user-tie red"></i><span class="ml-1">{{'contact_mypascom_account_admin_description' | i18next }}</span>
        </div>
        <div><i class="fa fa-user-circle red"></i><span class="ml-1">{{'contact_mypascom_account_enabled_description' | i18next }}</span>
        </div>
        <div><i class="fa fa-times-circle red"></i><span class="ml-1">{{'contact_mypascom_account_disabled_description' | i18next }}</span>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-2">
      <button *ngIf="isAdmin && !userIsActive && !loggedInUser && !isMypascomDisabled" class="btn btn-outline-primary" (click)="onResendInvitationEmail()">{{'auth_resend_invitation_email' | i18next}}</button>
      <button class="btn btn-primary ml-2"
              *ngIf="isAdmin" [disabled]="formGroup.status !== 'VALID'" (click)="onSave()">{{'app_save' | i18next}}</button>
    </div>
  </div>
  <p-confirmDialog key="change_userdata_confirm" #cd header="Confirmation" icon="pi pi-exclamation-triangle">
    <p-footer>
      <button type="button" class="btn btn-outline-primary" (click)="cd.reject()">{{(cd.rejectLabel ?? 'app_cancel') | i18next}}</button>
      <button type="button" class="btn btn-primary" (click)="cd.accept()">{{(cd.acceptLabel ?? 'app_update') | i18next}}</button>
    </p-footer>
  </p-confirmDialog>
</div>

<app-icon-card>
    <h3 class="h3 red mb-3">{{ 'manage_account' | i18next }}</h3>

    <p>{{ 'manage_account_profile_hint' | i18next: { linkName: ('manage_account' | i18next) } }}</p>

    <div class="d-flex mt-3 w-100 justify-content-end">
        <a [href]="accountManagerUrl" target="_pascom_manage_account">
            <span class="mr-2">{{ 'manage_account' | i18next }}</span>
            <i class="fa-solid fa-arrow-up-right-from-square"></i>
        </a>
    </div>
</app-icon-card>
