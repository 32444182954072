<div class="container-fluid"  *ngIf="!isInitialized">
  <div class="row">
    <div class="col">
      Loading...
    </div>
  </div>
</div>

<p-toast></p-toast>
<app-content-loading>
  <div *ngIf="!isMaintenance && isInitialized" class="app header-fixed sidebar-fixed" [ngClass]="{ 'sidebar-hidden': !showMenu}">
    <div class="app-header">
      <app-top-sticky-message-bar *ngIf="showStickyMessages"></app-top-sticky-message-bar>
      <app-top-navigation></app-top-navigation>
    </div>
    <div class="app-body h-100" [ngClass]="{ 'sticky-message-bar': showStickyMessages }">
      <div class="sidebar">
        <app-side-navigation></app-side-navigation>
      </div>
      <main class="main">
        <div id="router" class="p-2 p-md-5" *ngIf="!isAuthorizationFailed">
          <router-outlet *ngIf="!isAgreementNeeded"></router-outlet>
          <app-agreement-management *ngIf="isAgreementNeeded"></app-agreement-management>
        </div>
        <app-auth-failed *ngIf="isAuthorizationFailed"></app-auth-failed>
      </main>
    </div>
  </div>
  <app-maintenance *ngIf="isMaintenance && isInitialized"></app-maintenance>
  <app-footer *ngIf="isInitialized"></app-footer>
</app-content-loading>
