import {Injectable} from '@angular/core';
import {I18NextService} from 'angular-i18next';
import * as numeral from 'numeral';
import {autorun, observable, runInAction} from 'mobx';
import {Model} from './app.model';
import {HttpClient} from '@angular/common/http';
import {CurrentUser} from './auth/account.model';
import {browserLanguage, normalizedShortLang} from '@myshared/i18nutils';

@Injectable()
export class LanguageService {

  private _language: string;

  constructor(private i18next: I18NextService,
              private m: Model,
              private http: HttpClient) {
    autorun(() => {
      // On approval end customer step, we do not have a account
      if (this.m.account?.currentUser?.lang) {
        this._language = this.m.account?.currentUser?.lang;
      } else {
        // set browser language if no odoo account language is existing
        this._language = normalizedShortLang(browserLanguage());
      }

      this.setLanguage(normalizedShortLang(this._language));
    });
  }

  public changeLanguage(code: string) {
    this.http.post<any>('/services/user/save_lang', {lang: code}).subscribe(r => {
      runInAction(() => this.m.account.currentUser = CurrentUser.fromJson(r));
    });
  }

  public setLanguage(code: string) {
    const currentLang = normalizedShortLang(this.i18next.language);
    const normalizedLang = normalizedShortLang(code);
    if (currentLang === normalizedLang) {
      // Nothing to do...
      return;
    }
    numeral.locale(normalizedLang);

    // We cannot use the reload cycle in the invite workflow. This will cause the page to reload twice.
    const rerenderBlacklist = this.m.workflow.inWorkflow && this.m.workflow.isInvite;

    const oldInit = this.m.account.isInitialized;
    // Set init to false for one render-cycle, to *force* angular to redraw and reload everything
    runInAction(() => {
      if (rerenderBlacklist) return;
      this.m.account.isInitialized = false;
    });
    this.i18next.changeLanguage(normalizedLang).then(() => {
      // if isInitialized changed to true in the meantime, keep it to "true"
      setTimeout(() => runInAction(() => this.m.account.isInitialized = this.m.account.isInitialized || oldInit), 1);
    });
  }

  public get language() {
    return this._language;
  }

  public get tenantLanguage() {
    return `${normalizedShortLang(this._language)}-${this.tenantCountryCode}`;
  }

  private get tenantCountryCode() {
    return this.m.account?.currentUser?.tenant?.countryCode ?? this.m.buy?.currentOrder?.tenant?.countryCode ?? 'DE';
  }
}
