import {Model} from "./app.model";
import {autorun} from "mobx";
import {
  setDefaultOptions,
  getDefaultOptions,
  format,
  isAfter,
  isBefore,
  sub,
  add,
  differenceInDays,
  getDay,
  toDate,
  set,
} from 'date-fns';
import type {Duration} from 'date-fns';
import {browserLanguage, normalizedShortLang} from "@myshared/i18nutils";
import {de, enGB} from "date-fns/locale";
import {Injectable} from "@angular/core";

/**
 * This service exists only so that the set language is automatically applied to the date.
 * This service is injected once in the app.component.ts, and listens for appropriate changes via mobs Autorun.
 */
@Injectable()
export class DateService {
  private language: string;

  constructor(private m: Model) {
    autorun(() => {
      // Automatically change locale for date on detected language change
      const lng = this.m.account.currentUser.lang;

      const normalizedLang = normalizedShortLang(lng ?? browserLanguage())

      if (normalizedLang) {
        if (normalizedLang !== this.language) {
          this.language = normalizedLang;
          // Override the default options for the date after the language has been changed (e.g. loaded from user API call)
          setDefaultOptions({ locale: (this.language === 'de' ? de : enGB),  weekStartsOn: 1 });
        }
      }
    })
  }

  public init() {
    setDefaultOptions({ locale: de,  weekStartsOn: 1 }); // Set always the standard date to german
  }
}

type PcDateInput = string | Date | number;

export enum Days {
  SUNDAY = 0,
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
}

export type DateSetValues = {
  year?: number
  month?: number
  date?: number
  hours?: number
  minutes?: number
  seconds?: number
  milliseconds?: number
}

/**
 * Static pcDate wrapper function
 *
 * Standard used library is date-fns for all formats and calculations.
 * You can easily chain methods for calculate, output etc.
 *
 * @param customDate
 */
export function pcDate(customDate?: PcDateInput) {
  const date = customDate ? getDate(customDate) : Date.now();

  function getDate(date: PcDateInput): Date | number {
    return (date instanceof Date || typeof date === 'number') ? date : new Date(date);
  }

  return {
    format(dateFormat: string = 'yyyy-MM-dd') {
      return format(date, dateFormat, getDefaultOptions());
    },

    formatDate(): string {
      return format(date, 'P', getDefaultOptions());
    },

    formatDateTime(): string {
      return format(date, 'Pp', getDefaultOptions());
    },

    isAfter(dateToCompare: PcDateInput): boolean {
      return isAfter(date, getDate(dateToCompare));
    },

    isBefore(dateToCompare: PcDateInput) {
      return isBefore(date, getDate(dateToCompare));
    },

    sub(duration: Duration) {
      return pcDate(sub(date, duration));
    },

    add(duration: Duration) {
      return pcDate(add(date, duration));
    },

    diffInDays(diffDate: PcDateInput) {
      return differenceInDays(date, getDate(diffDate))
    },

    day(): Days {
      return getDay(date)
    },

    set(dateValues: DateSetValues) {
      return pcDate(set(date, dateValues));
    },

    toDate() {
      return toDate(date);
    }
  }
}
